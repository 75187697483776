<template>
	<div class="about">
		<div class="banner">
			<headers></headers>
			<img src="../../assets/image/aboutbanner.png" alt="">
		</div>
		<div class="breadcrumb box">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>关于我们</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="nav box">
			
			<div v-for="(item,index) in Navlist" :key="index" :class="[navclick == index?'nav_class click ':'nav_class']">
				<a @click="navclick = index,navtext = item">{{item}}</a>
				<span class="round"></span>
			</div>
			<!-- <div :class="[navclick == index?'nav_class solid click':'nav_class solid']">
				<a @click="navclick = index,navtext = item">行业优势</a>
				<span class="round"></span>
			</div>
			<div :class="[navclick == index?'nav_class click':'nav_class ']">
				<a @click="navclick = index,navtext = item">发展历程</a>
				<span class="round"></span>
			</div> -->
		</div>
		<div class="introduction box" id="introduction">
			<div class="left">
				<img src="../../assets/image/abwork.png" alt="">
			</div>
			<div class="right">
				<div class="titles">
					<div class="dv1">{{navtext}}</div>
					<div class="dv2">Product introduction</div>
				</div>
				<p class="content">故事从上世纪的文革初开始讲起，对生于九十年代末的我来说，只能用所有关于贫穷的想象来勾勒双水村的生活样貌。路遥通过描写田、孙、金三大家族由文革初至改革开放后生活境遇的变化,刻画苦难中的人物及命运曲线，反映了中国二十年的时代变迁。</p>
			</div>
		</div>
		<div class="advantage box" id="advantage">
			<div class="titles">
				<div class="dv1">行业优势</div>
				<div class="dv2">Industry advantages</div>
			</div>
			<p class="tit">
				这里是简介这里是简介这里是简介这里是简介这里是简介这里是简介这里故事从上世纪的文革初开始讲起，对生于九十年代末的我来说，只能用所有关于贫穷的想象来勾勒双水村的生活样貌。路遥通过描写田、孙、金三大家族由文革初至改革开放后生活境遇的变化
			</p>
			<transition name="el-zoom-in-center">
				<div class="list" v-show="dhshow">
					<div class="list_body">
						<img class="list_img" src="../../assets/image/首页2_07.png" alt="">
						<span class="list_tit">优势一</span>
						<span class="list_content">简单文字描述，简单文字描述简单文字描述简单文字描述简单文字描述</span>
					</div>
					<div class="list_body">
						<img class="list_img" src="../../assets/image/首页2_09.png" alt="">
						<span class="list_tit">优势二</span>
						<span class="list_content">简单文字描述，简单文字描述简单文字描述简单文字描述简单文字描述</span>
					</div>
					<div class="list_body">
						<img class="list_img" src="../../assets/image/首页2_11.png" alt="">
						<span class="list_tit">优势三</span>
						<span class="list_content">简单文字描述，简单文字描述简单文字描述简单文字描述简单文字描述</span>
					</div>
					<div class="list_body">
						<img class="list_img" src="../../assets/image/首页2_13.png" alt="">
						<span class="list_tit">优势四</span>
						<span class="list_content">简单文字描述，简单文字描述简单文字描述简单文字描述简单文字描述</span>
					</div>
				</div>
			</transition>
		</div>
		<div class="development" id="development">
			<div class="titles">
				<div class="dv1">发展历程</div>
				<div class="dv2">development history</div>
			</div>

			<div class="body">
				<div class="box">
					<div class="left">
						<img @click="development>0? development-- : ''" class="top" src="../../assets/image/lefticon2.png" alt="">
						<ol>
							<li v-for="(item,index) in development_time" :class="[development == index ? 'click':'']">{{item}}</li>
						</ol>
						<img @click="development == development_time.length-1? '' : development++" class="bot" src="../../assets/image/lefticon.png"
						 alt="">
					</div>
					<div class="right">
						<div class="img">
							<img src="../../assets/image/czd3.png" alt="">
						</div>
						<p class="tit">取得行业排名前五的好成绩</p>
						<p class="cont">介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</p>
					</div>
				</div>

			</div>
			<div class="body_phone">
				<van-swipe class="my-swipe" :loop="false" :show-indicators="false">
					<van-swipe-item>
						<p class="time">2016-2017</p>
						<div class="img">
							<img src="../../assets/image/czd3.png" alt="">
						</div>
						<p class="tit">取得行业排名前五的好成绩</p>
						<p class="cont">介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</p>
					</van-swipe-item>
					<van-swipe-item>
						<p class="time">2016-2017</p>
						<div class="img">
							<img src="../../assets/image/czd3.png" alt="">
						</div>
						<p class="tit">取得行业排名前五的好成绩</p>
						<p class="cont">介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍</p>
					</van-swipe-item>
				</van-swipe>
			</div>
		</div>
		<Foot></Foot>
	</div>
</template>

<script>
	import headers from '../../components/header.vue'
	import Foot from '../../components/Foot.vue'
	import { Swipe, SwipeItem } from 'vant';
	export default {
		components: {
			headers,
			Foot,
			[Swipe.name]: Swipe,
			[SwipeItem.name]: SwipeItem
		},
		data() {
			return {
				navclick: 0,
				dhshow: false,
				screenWidth: '',
				development_time: ['2015-2016', '2016-2017', '2017-2018', '2016-2018', '2016-2019', '2020-2021'],
				development: 0,
				Navlist:['企业简介','企业文化','品牌故事'],
				navtext:''
			}
		},
		mounted() {
			let that = this
			let l = document.documentElement.clientWidth
			that.screenWidth = l
			if (l < 900) {
				this.dhshow = true
			}
			window.addEventListener('scroll', that.handleScrollx, true)
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth
					that.screenWidth = window.screenWidth
					console.log(that.screenWidth)
				})()
			}
		},
		methods: {
			handleScrollx() {
				var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
				if (this.screenWidth > 900) {
					if (top >= 660) {
						this.dhshow = true
					} else {
						this.dhshow = false
					}
				}
				if(this.screenWidth<900){
					if (top >= 660) {
						this.dhshow = true
					} else {
						this.dhshow = false
					}
				}
				//console.log('滚动高度', window.pageYOffset)
				// console.log('滚动高度', top)
				// console.log('距离顶部高度', this.$refs.obtain.getBoundingClientRect().top)
			},
			changeHash(idname){
				document.querySelector(idname).scrollIntoView(true);
			}
		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/less/about_index";
</style>
<style>

</style>
